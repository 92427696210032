<template>
  <!-- 专家回答问题 -->
  <div class="enroll_page">
    <div class="enroll_wrapper">
      <div class="enroll_title">{{ title }}</div>
      <div class="time">{{ time }}<span></span> {{ createDeptName }}</div>
      <img v-if="cover" class="cover" :src="cover" alt="" />
      <div v-html="content" class="enroll_content"></div>
    </div>
  </div>
</template>
<script>
import { getAskDetail } from '@/api/eventApi'
export default {
  props: {
    id: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      title: '',
      cover: '',
      content: '',
      time: '',
      createDeptName: '',
    }
  },
  created() {
    this.getInfo(this.id)
  },
  // watch: {
  //   id(val) {
  //     if(val) {
  //       this.getInfo(this.id)
  //     }
  //   }
  // },
  mounted() {},
  methods: {
    getInfo(id) {
      this.$store.commit('SET_IS_LOADING', true)
      getAskDetail({
        id: id,
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        this.title = res.data.title
        this.cover = res.data.cover
        this.content = res.data.content
        this.time = res.data.createTime
        this.createDeptName = res.data.createDeptName
        
      })
    },

  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.cover {
  width: 100%;
  height: auto;
}
.enroll_page {
  padding: 0.3rem;
  background: #fff;
  position: relative;
  min-height: 100%;
  .enroll_wrapper {
    // flex: 1;
    height: 100%;
    overflow-y: auto;
    .enroll_title {
      padding: 0.3rem 0;
      font-weight: 600;
      // text-align: center;
      font-size: 0.5rem;
    }
    .enroll_content {
      padding-top: 0.2rem;
      font-size: 0.3rem;
    }
    .time {
      width: 100%;
      color: #a1a1a1;
      font-size: 0.4rem;
      margin-bottom: 0.5rem;
      & > span {
        margin-right: 0.2rem;
      }
    }
    padding-bottom: 1rem;
  }
  .enroll_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 1rem;
  }
}
</style>
